import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import {
  ConnectionService,
  ConnectionState,
} from './shared/services/connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  connectionSubscription: Subscription = new Subscription();
  status!: string;
  currentState!: ConnectionState;

  constructor(private connectionService: ConnectionService) {
    window.onoffline = (event) => {
      console.log(event);
      // alert('Mneti imehepa');
    };

    window.ononline = (event) => {
      console.log('You are now connected to the network.');
    };
  }

  ngOnInit(): void {
    this.connectionSubscription.add(
      this.connectionService
        .monitor()
        .pipe(
          tap((newState: ConnectionState) => {
            this.currentState = newState;
            if (newState.hasNetworkConnection && newState.hasInternetAccess) {
              this.status = 'ONLINE';
            } else {
              this.status = 'OFFLINE';
            }
            console.debug('🔥 newState', newState);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.connectionSubscription.unsubscribe();
  }
}
